.modal-outer {
  background-color: rgba(0, 0, 0, 0.322);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150vh;
  width: 100vw;
}

.modal-container {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.89);
  display: flex;
  flex-direction: column;
  padding: 0 5% 7% 5%;
} 

.close-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0%;
  margin-right: 0;

}

.close-btn, .exit {
  align-items: end;
  margin-right: -8%;
  padding-top: 2%;
  font-size: 20px;
  color: #786fa6;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s linear;
}

.close-btn, .exit:hover {
  transform: scale(1.4);
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-heading {
  color: #786fa6;
}
