* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";

  
}

body {
  background-image: url("./assets/bodyBg2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  min-height: 100%;
  
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  color:  rgb(116, 116, 116);
  text-align: center;
  
}