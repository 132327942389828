.form-container {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10%;
  margin-top: 5%;
  border-radius: 10px;
  text-align: left;
}

#name, #url {
  border: none;
  background-color: rgb(218, 218, 218);
  border-radius: 5px;
  padding: 3px;
}

#url {
  margin-bottom: 5%;
}

.formSub {
    margin-top: 3%;
    padding: 3%;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    color: #786fa6;
  }
