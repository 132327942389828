.favourite-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.link-name {
  margin-top: -5%;
  font-size: 30px;
  font-weight: 500;
  color: rgb(214, 214, 214);
}

.add-card {
  font-size: 40px;
  height: 100px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(214, 214, 214);
  border: none;
}

.add-card:hover {
  color: #786fa6;
  cursor: pointer;
}


.delFave {
  position: absolute;
  top: -7%; 
  left: 45%;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  color: rgb(138, 138, 138);
  font-size: 13px;
  cursor: pointer;
  transition: 1s linear;
  padding: 0;
  margin-bottom: 0;
  transition: 0.8ms;
}

.delFave:hover {
  color: #786fa6;

}