.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: auto;
  color: #786fa6;
  text-align: center;
}

.headerH1 {
  margin: auto;
  font-size: 50px;
  font-weight: 500;
}