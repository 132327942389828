.temp-box {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.1); /* Black w/opacity/see-through */
  margin-bottom: 3%;
  padding: 1%;
  border-radius: 5px;

}
.shortcuts, .add-cards {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 150px;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  margin-bottom: 3%;

  border-radius: 5px;
  margin: 1%;
  cursor: default;
}

.add-cards:hover {
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity/see-through */
  cursor: pointer;
}